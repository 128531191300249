<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                  <el-row>
                                    <el-col :span="24" align="right">
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_btn">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="1" >Print</el-dropdown-item>                                          
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                        <el-col :span="12" align="left" v-if="view.website_details">
                                          <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}},{{view.website_details.state_details.state_name}} - {{view.website_details.post_code}}, {{view.website_details.country}}.</label><br>                                      
                                        <label>Phone: {{view.website_details.phone}}</label><br>
                                        <label>Fax: {{view.website_details.fax}}</label><br>
                                        <label>Email: {{view.website_details.email_address}}</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>Credit Memo</b></h2>
                                      </el-col>                                                                            
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER : </b></span><span><b> {{view.order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID : </b></span><span><b>{{view.customer.customer_id}}</b></span>
                                       </el-col>
                                      
                                  </el-row>
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                          <div style="padding:3px" v-if="view.customer"><span v-if="view.customer.first_name">{{view.customer.first_name}}</span><span v-if="view.customer.last_name">{{view.customer.last_name}}</span></div>
                                          <div style="padding: 3px;" ><span v-if="view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}</span><span v-if="view.billing_address_order.address_line_1">, Unit No : {{view.billing_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name}}<span v-if="view.billing_address_order.post_code"> - {{view.billing_address_order.post_code}}</span></div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                         <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                          <div style="padding: 3px;" ><span v-if="view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}</span><span v-if="view.shipping_address_order.address_line_1">, Unit No : {{view.shipping_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name}}<span v-if="view.shipping_address_order.post_code"> - {{view.shipping_address_order.post_code}}</span></div>
                                      </el-col>
                                    </el-row>                                   
                                      <div class="row">
                                      <div class="col-xl-12 ">  
                                          <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center" style="background-color:#366092; color: #ffffff">
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >PRODUCT NAME</div></th>
                                                    <th aria-colindex="2" role="cell"><div >CANCEL QTY</div></th>                                                     
                                                    <th aria-colindex="2" role="cell"><div >PRICE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >TOTAL AMOUNT</div></th>
                                                </tr>
                                                <tr class="billing-cl3" v-for="item in cancelOrder.cancel_items" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product.sku}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product.name}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{item.cancel_quantity}}</div></td> 
                                                    <td aria-colindex="2" role="cell" class="text-center" v-if="item.price"><div>${{item.price}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>${{item.sub_total}}</div></td>
                                                </tr>
                                               <tr class="billing-cl3">
                                                  <td aria-colindex="2" role="cell" colspan="3" >
                                                      <div class="padding-10"><b><u>Cancel Reason</u></b></div>
                                                      <div class="padding-10">{{cancelOrder.cancel_reason}}</div>
                                                      <div class="padding-10" style="margin-top:40px;" ></div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" >
                                                      <div class="padding-10">Sub Total</div> 
                                                      <div class="padding-10">Tax (13 %)</div>                                                    
                                                      <div class="padding-10" v-if="cancelOrder.adjustment_amount">Adjustment Amount</div>                                                       
                                                      <hr class="invoice-line">
                                                      <div class="padding-10">Total</div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" class="text-center">
                                                      <div class="padding-10">${{cancelOrder.sub_total}}</div>  
                                                      <div class="padding-10">${{cancelOrder.tax_total}}</div>                                                     
                                                      <div class="padding-10" v-if="cancelOrder.adjustment_amount">${{cancelOrder.adjustment_amount}}</div>                                                      
                                                      <hr class="invoice-line">
                                                      <div class="padding-10">${{cancelOrder.cancel_amount}}</div>
                                                      </td>  
                                                </tr>
                                            </tbody>
                                        </table>                
                                       </div>
                                      </div>                                
                                       <el-row>                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>  
                                       <el-row class="mt-5 text-right" v-if="cancelOrder.refund_payment">
                                         <el-col>
                                           <span v-if="cancelOrder.return_amount_type == 'refund_online'">
                                              Refund sent {{cancelOrder.refund_payment.brand}} **** {{cancelOrder.refund_payment.last_4}} : <b>${{cancelOrder.cancel_amount}}</b>
                                           </span>
                                           <span v-else>
                                             Refund sent credit memo : <b>${{cancelOrder.cancel_amount}}</b>
                                           </span>
                                         </el-col>
                                       </el-row>                                  
                              
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getCancelCreditMemoView} from "@/api/order"
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      cancelOrder:[]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },     
      { title: "Credit Memo View" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  methods: {
    fetchData() {
      this.loading = true
     
      getCancelCreditMemoView(this.$route.params.credit_memo_id).then(response => {
        this.view = response.data.data 
        this.cancelOrder = response.data.cancelOrder
        this.loading = false
      });
    },    
   
    handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }
    },
    printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    },
   
  }
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
     .return_product .el-input__inner {
            /* padding-left: 0px;
            padding-right: 8px; */
            border: 1px solid #3699ff;
            text-align: end !important;
        }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 

    
    

}
 
</style>